import React, { useEffect, useState } from "react";
import { useStations } from "../../context/StationsContext";
import { useUser } from "../../context/UserContext";
import { useFavorites } from "../../context/FavoritesContext";
import { translate } from "../../i18n";

import { getAllFavoritesByTelegramId } from "../../service/favorites/favorites";
import {
  getCountries,
  getGenres,
  StationInterface,
} from "../../service/stations/stations";

import Search from "../../components/Search/Search";
import Filter from "../../components/Search/Filter/Filter";

import { ReactComponent as NoteIcon } from "../../assets/icons/stationMusicIcon.svg";
import { ReactComponent as FavoriteIcon } from "../../assets/icons/favoriteIcon.svg";
import { ReactComponent as FavoriteActiveIcon } from "../../assets/icons/favoriteActiveIcon.svg";
import Icon from "../../assets/icons/favoriteIcon.png";
import FavoriteActive from "../../assets/icons/FavoriteActive.png";
import styles from "./stations.module.css";
import { usePlayer } from "../../context/PlayerContext";

const Stations: React.FC<{
  setStation: (newVal: number) => void;
  lang: "en" | "ru";
}> = ({ setStation, lang }) => {
  const { user, handleGetUserByTelegramId } = useUser();
  const { favorites, handleGetFavoritesByUser, handleToggleFavorite } =
    useFavorites();
  const {
    station: activeStation,
    stations,
    handleSelectStation,
    handleGetStations,
    activeTab,
    setActiveTab,
    lastStation,
    handleGetLastStation,
    handlePostLastStation,
  } = useStations();
  const { setIsPlaying } = usePlayer();

  const [loading, setLoading] = useState(false);
  const [genres, setGenres] = useState<any[]>([]);
  const [countries, setCountries] = useState<any[]>([]);

  const [value, setValue] = useState("");
  const [activeGenre, setActiveGenre] = useState(0);
  const [activeCountry, setActiveCountry] = useState(0);

  const [filteredStations, setFilteredStations] = useState<StationInterface[]>(
    []
  );

  const handleGenreChange = (id: number) => setActiveGenre(id);
  const handleCountryChange = (id: number) => setActiveCountry(id);

  const handleChange = (val: string) => setValue(val);

  const fetchGenres = async () => {
    const data = await getGenres();
    setGenres(data);
  };

  const fetchCountries = async () => {
    const data = await getCountries();
    setCountries(data);
  };

  const handleFavoriteClick = async (event: any, station: StationInterface) => {
    event.stopPropagation();
    try {
      await handleToggleFavorite(station, user?.telegram_id);
      await getAllFavoritesByTelegramId(user?.telegram_id);
      await handleGetUserByTelegramId(user?.telegram_id.toString());
    } catch (error) {
      console.error(error);
    }
  };

  const fetchStations = async () => {
    if (user) {
      setLoading(true);

      await handleGetStations(lang);

      await handleGetFavoritesByUser(user?.telegram_id);

      setLoading(false);
    }
  };

  useEffect(() => {
    if (stations?.length) {
      let filtered = activeTab === 0 ? stations : favorites;

      if (filtered?.length) {
        if (value) {
          filtered = filtered.filter((station) =>
            station.name.toLowerCase().includes(value.toLowerCase())
          );
        }

        if (activeGenre !== 0) {
          filtered = filtered.filter((station) =>
            station.genres.some((genre: any) => genre.id === activeGenre)
          );
        }

        if (activeCountry !== 0) {
          filtered = filtered.filter(
            (station) =>
              station.country?.name ===
              countries?.find((country) => country.id === activeCountry).name
          );
        }
      }

      setFilteredStations(filtered);
    }
  }, [activeGenre, activeCountry, value, stations, activeTab]);

  useEffect(() => {
    fetchGenres();
    fetchCountries();
    fetchStations();
    handleGetLastStation();
  }, []);

  useEffect(() => {
    if (lastStation && stations) {
      const stationToPlay = stations.find(
        (st) => st.id === lastStation.station
      );
      if (stationToPlay) {
        handleSelectStation(stationToPlay, lang);
        // setIsPlaying(true);
      }
    }
  }, [lastStation, stations]);

  console.log(activeCountry);
  console.log(countries);

  const handleStationClick = async (station: StationInterface) => {
    handleSelectStation(station, lang);
    setIsPlaying(true);
    console.log(station.id);
    handlePostLastStation(user.telegram_id.toString(), station.id.toString());
    // try {
    //   await handleGetUserByTelegramId(user?.telegram_id.toString());
    // } catch (error) {
    //   handleSelectStation("", lang);
    // }
  };
  console.log("Last station", lastStation);
  return (
    <div
      style={{ width: "100%", minWidth: "calc(100vw - 40px)", height: "100%" }}
    >
      <div className={styles.topButtons}>
        <button
          className={`${styles.claimButton} ${
            activeTab === 0 ? styles.active : ""
          }`}
          onClick={() => {
            setActiveTab(0);
          }}
        >
          <p>All Stations</p>
        </button>
        <button
          className={`${styles.claimButton} ${
            activeTab === 1 ? styles.active : ""
          }`}
          onClick={() => {
            setActiveTab(1);
          }}
        >
          <p>Favorites</p>
        </button>
      </div>
      <Search
        value={value}
        onChange={handleChange}
        lang={lang}
      />
      <div className={styles.filterWrapper}>
        <Filter
          position={"left"}
          activeFilter={activeCountry}
          title={translate("countries", lang)}
          options={countries}
          onOptionSelect={handleCountryChange}
          lang={lang}
        />
        <Filter
          position={"right"}
          activeFilter={activeGenre}
          title={translate("genres", lang)}
          options={genres}
          onOptionSelect={handleGenreChange}
          lang={lang}
        />
      </div>
      <div
        style={{
          maxHeight: "100%",
          height: "calc(100% - 177px)",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        {Array.isArray(filteredStations) && !!filteredStations.length ? (
          filteredStations.map((station) => (
            <div
              className={`${styles.itemWrapper} ${
                station.id === activeStation?.id ? styles.active : ""
              }`}
              onClick={() => {
                handleStationClick(station);
              }}
              key={station.id}
            >
              <button className={styles.playButton}>
                <NoteIcon />
              </button>
              <div className={styles.infoWrapper}>
                <p className={styles.title}>{station.name}</p>
                {station?.description && (
                  <p className={styles.description}>{station.description}</p>
                )}
              </div>
              <div
                className={styles.favoriteIcon}
                onClick={(event) => handleFavoriteClick(event, station)}
              >
                {Array.isArray(favorites) &&
                favorites.find((fav) => fav.id === station.id) ? (
                  <div className={styles.wrapperIcon}>
                    <img
                      src={FavoriteActive}
                      alt=""
                      className={styles.favoriteActiveimg}
                    />
                  </div>
                ) : (
                  <div className={styles.wrapperIcon}>
                    <img
                      src={Icon}
                      alt=""
                      className={styles.favoriteimg}
                    />
                  </div>
                )}
              </div>
            </div>
          ))
        ) : loading ? (
          <div>{translate("stations_loading", lang)}</div>
        ) : (
          <div>{translate("no_stations", lang)}</div>
        )}
      </div>
    </div>
  );
};

export default Stations;
