import React, { useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player";
import { IconButton, Typography } from "@mui/material";
import { styled } from "@mui/system";

import { ReactComponent as GoToFavoritesIcon } from "../../assets/icons/goToFavIcon.svg";
import { ReactComponent as PlayArrowIcon } from "../../assets/icons/player/playIcon.svg";
import { ReactComponent as PauseIcon } from "../../assets/icons/player/pauseIcon.svg";
import { ReactComponent as SkipNextIcon } from "../../assets/icons/player/nextPlayIcon.svg";
import { ReactComponent as SkipPreviousIcon } from "../../assets/icons/player/prevPlayIcon.svg";
import { ReactComponent as RandomMusic } from "../../assets/icons/randomMusic.svg";
import { ReactComponent as RandomMusicActive } from "../../assets/icons/randomActive.svg";
import { ReactComponent as CircleMusic } from "../../assets/icons/circle.svg";
import { ReactComponent as CircleMusicActive } from "../../assets/icons/circleActive.svg";
import { ReactComponent as VolumeIcon } from "../../assets/icons/player/volumtIcon.svg";

import { useUser } from "../../context/UserContext";
import { useStations } from "../../context/StationsContext";
import { useFavorites } from "../../context/FavoritesContext";
import { ReactComponent as FavoriteActiveIcon } from "../../assets/icons/favoriteActiveIcon.svg";
import { ReactComponent as FavoriteIcon } from "../../assets/icons/favoriteIcon.svg";

import styles from "./PlayerControlsMusicians.module.css";
import { usePlayer } from "../../context/PlayerContext";
import TimeControl from "../TimerControl/TimerControl";
import { useMusic } from "../../context/MusicContext";
import useWebSocketWithReconnect from "../../hooks/useWebSocket";
import { BASE_ORIGIN } from "../../service/axiosConfig/axiosConfig";
import { getWalletByTelegramId } from "../../service/wallets/wallets";
import { MusiciansAlbumSongsResponse } from "../../service/music/music";
import UserScore from "../UserScoreMusicians/UserScore";

const PlayerContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "max-content",
  color: "#FFFFFF",
  padding: "0 20px 20px",
  borderRadius: "10px",
}));

const ControlsContainer = styled("div")(() => ({
  display: "flex",
  width: "205px",
  alignItems: "center",
  justifyContent: "space-between",
}));

const TimeControlContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}));

const StationName = styled(Typography)(() => ({
  fontSize: "21px",
  fontWeight: "400",
  fontFamily: "Abel",
  color: "#30C9F2",
  textAlign: "center",
  width: "100%",
}));

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const hrs = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const remainingSeconds = Math.floor(seconds % 60);

  return `${hrs > 0 ? `${hrs}:` : ""}${
    remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes
  }:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
};
// @ts-ignore
export const tg = window.Telegram.WebApp;

const PlayerControlsMusicians: React.FC<{
  lang: "en" | "ru";
  onTabChange: (value: number) => void;
}> = ({ lang, onTabChange }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const telegramId = urlParams.get("telegram_id");
  const { user, setUser } = useUser();
  const {
    musicFavorites,
    handleToggleMusicFavorite,
    handleGetMusicFavoritesByUser,
  } = useFavorites();
  const {
    songs,
    setSongs,
    song,
    handleSelectSong,
    setActiveTab,
    isPlayingFromFavorites,
    handleGetLastSong,
    lastSong,
  } = useMusic();

  const [volume, setVolume] = useState(50);
  const { isPlaying, setIsPlaying, handlePlayPause } = usePlayer();
  const [score, setScore] = useState<number>(0);
  const [updatedScore, setUpdatedScore] = useState(0);
  const [progress, setProgress] = useState(0);
  const [progressScore, setProgressScose] = useState(progress);
  const [duration, setDuration] = useState(0);
  const [shuffledSongs, setShuffledSongs] = useState<
    MusiciansAlbumSongsResponse[]
  >([]);
  const [isShuffledSongs, setIsShuffledSongs] = useState(false);
  const [circuleSong, setCirculeSong] = useState(false);
  const pointsAccumulated = useRef(0);
  const playerRef = useRef<ReactPlayer | null>(null);
  const [isSongFullyPlayed, setIsSongFullyPlayed] = useState(false);
  const [isSeeking, setIsSeeking] = useState(false);

  const handleScoreUpdate = (score: number) => {
    console.log("Updated Score Received:", score);
    setUpdatedScore(score);
    if (user) {
      setUser((prev: any) => ({
        ...prev,
        wallet: { ...user.wallet, balance: score },
      }));
    }
  };
  const { ws, isConnected } = useWebSocketWithReconnect(
    `wss://${BASE_ORIGIN}/ws/score/${user.telegram_id}/`,
    5,
    handleScoreUpdate
  );
  const handleVolumeChange = (newVolume: number) => {
    setVolume(newVolume);
  };

  const handleGoToFavList = () => {
    setActiveTab(2);
    onTabChange(2);
  };

  const shuffleSongs = (
    array: MusiciansAlbumSongsResponse[]
  ): MusiciansAlbumSongsResponse[] => {
    return [...array].sort(() => Math.random() - 0.5);
  };

  const handlePrevious = (id: number) => {
    if (isSeeking) {
      setIsSeeking(false);
    }
    if (!isPlayingFromFavorites) {
      const currentIndex = isShuffledSongs
        ? shuffledSongs.findIndex((song) => song.id === id)
        : songs.findIndex((song) => song.id === id);
      const previousIndex =
        (currentIndex -
          1 +
          (isShuffledSongs ? shuffledSongs.length : songs.length)) %
        (isShuffledSongs ? shuffledSongs.length : songs.length);
      handleSelectSong(
        isShuffledSongs ? shuffledSongs[previousIndex] : songs[previousIndex]
      );
    } else {
      const currentIndex = musicFavorites.findIndex(
        (item) => item.song.id === id
      );
      const previousIndex =
        (currentIndex - 1 + musicFavorites.length) % musicFavorites.length;
      handleSelectSong(musicFavorites[previousIndex].song);
    }
    if (!isPlaying) {
      setIsPlaying(true);
    }
    setCirculeSong(false);
  };

  const handleNext = (id: number) => {
    if (isSeeking) {
      setIsSeeking(false);
    }
    if (!isPlayingFromFavorites) {
      const currentIndex = isShuffledSongs
        ? shuffledSongs.findIndex((song) => song.id === id)
        : songs.findIndex((song) => song.id === id);
      const nextIndex =
        (currentIndex + 1) %
        (isShuffledSongs ? shuffledSongs.length : songs.length);
      handleSelectSong(
        isShuffledSongs ? shuffledSongs[nextIndex] : songs[nextIndex]
      );
    } else {
      const currentIndex = musicFavorites.findIndex(
        (item) => item.song.id === id
      );
      const nextIndex = (currentIndex + 1) % musicFavorites.length;
      handleSelectSong(musicFavorites[nextIndex].song);
    }
    if (!isPlaying) {
      setIsPlaying(true);
    }
    setCirculeSong(false);
  };

  const handleCircleSong = (id: number) => {
    const currentIndex = songs.findIndex((song) => song.id === id);
    handleSelectSong(songs[currentIndex]);
    setCirculeSong(true);
    setIsPlaying(true);
  };

  const handleToggleCircle = () => {
    setCirculeSong(!circuleSong);
  };

  const handleRandomSong = () => {
    if (isShuffledSongs) {
      setIsShuffledSongs(false);
      setSongs(songs);
    } else {
      const shuffled = shuffleSongs(songs);
      setShuffledSongs(shuffled);
      setIsShuffledSongs(true);
    }
  };

  const handleTimeChange = (newTime: number) => {
    setIsSeeking(true);
    setProgress(newTime);
    playerRef.current?.seekTo(newTime);
  };

  const handleProgress = (state: { playedSeconds: number; played: number }) => {
    setProgress(state.playedSeconds);
    setProgressScose(progress);
    if (isSeeking) {
      setProgressScose(0);
    }
    if (state.played >= 0.8) {
      setIsSongFullyPlayed(true);
    } else {
      setIsSongFullyPlayed(false);
    }
  };

  const handleSongEnd = () => {
    if (song) {
      if (isSongFullyPlayed && !isSeeking) {
        const pointsEarned = song.impulse * user.boost_multiplier;
        if (isConnected && ws?.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify({ points: pointsEarned }));
        }
        if (user) {
          setUser((prev: any) => ({
            ...prev,
            wallet: {
              ...prev?.wallet,
              balance: (
                parseInt(prev?.wallet?.balance) + pointsEarned
              ).toString(),
            },
          }));
        }
      }
      if (isSeeking) {
        setProgressScose(0);
      }
      setIsSongFullyPlayed(false);
      if (circuleSong) {
        handleCircleSong(song.id);
      } else {
        handleNext(song.id);
        setIsSeeking(false);
        setProgressScose(progress);
      }
    }
  };

  console.log(isSeeking);

  // const handleProgress = (state: { playedSeconds: number }) => {
  //   setProgress(state.playedSeconds);
  //   if (song) {
  //     const pointsPerSecond = parseFloat(song.impulse.toString()) / 60;
  //     pointsAccumulated.current += pointsPerSecond;
  //     const newScore =
  //       parseFloat(user?.wallet?.balance) + pointsAccumulated.current;
  //     setScore(newScore);
  //   }
  // };

  const handleDuration = (duration: number) => {
    setDuration(duration);
  };

  useEffect(() => {
    if (user) {
      setUpdatedScore(parseInt(user.wallet.balance));
      handleGetMusicFavoritesByUser(user.telegram_id);
    }
  }, [user]);

  const handleUpdateUserBalance = async () => {
    const data = await getWalletByTelegramId(telegramId as string);

    setUpdatedScore(parseInt(data.balance));
  };

  useEffect(() => {
    console.log("viewport change");

    tg.onEvent("viewportChanged", handleUpdateUserBalance);
  }, []);

  // useEffect(() => {
  //   if (isConnected && isPlaying) {
  //     const intervalId = setInterval(() => {
  //       if (ws?.readyState === WebSocket.OPEN && song) {
  //         const points = song.impulse * user.boost_multiplier;
  //         ws.send(JSON.stringify({ points }));
  //         pointsAccumulated.current = 0;
  //         if (user) {
  //           setUser((prev: any) => {
  //             return {
  //               ...prev,
  //               wallet: {
  //                 ...prev?.wallet,
  //                 balance: (
  //                   parseInt(prev?.wallet?.balance) + points
  //                 ).toString(),
  //               },
  //             };
  //           });
  //         }
  //       } else {
  //         console.warn("WebSocket connection is not open. Retrying...");
  //       }
  //     }, 60000);

  //     return () => {
  //       clearInterval(intervalId);
  //     };
  //   }
  // }, [isConnected, isPlaying]);

  useEffect(() => {
    if (user) {
      setUpdatedScore(parseFloat(user.wallet.balance));
    }
  }, [user]);

  useEffect(() => {
    console.log(shuffledSongs);
  }, [shuffledSongs]);
  return (
    <PlayerContainer>
      <UserScore
        isPlaying={isPlaying && !!song && !!song.id}
        lang={lang}
        onTabChange={onTabChange}
        score={!!updatedScore ? updatedScore.toFixed(0) : score.toFixed(0)}
        music="song"
        volume={volume}
        onVolumeChange={handleVolumeChange}
        duration={duration}
        progress={progressScore}
      />
      <div className={styles.infoWrapper}>
        <div
          style={{ cursor: "pointer" }}
          onClick={async () => {
            try {
              if (song) {
                await handleToggleMusicFavorite(song, user?.telegram_id);
                await handleGetMusicFavoritesByUser(user?.telegram_id);
              }
            } catch (error) {
              console.error(error);
            }
          }}
        >
          {Array.isArray(musicFavorites) &&
          musicFavorites.find((fav) => fav.song.id === song?.id) ? (
            <FavoriteActiveIcon className={styles.icon} />
          ) : (
            <FavoriteIcon className={styles.icon} />
          )}
        </div>
        <StationName>
          {isPlayingFromFavorites
            ? musicFavorites.map((item) => {
                if (item.song.id === song?.id) {
                  return (
                    <span
                      key={item.song.id}
                    >{`${item.song.musician.name} - ${item.song.title}`}</span>
                  );
                }
                return null;
              })
            : song
            ? `${song.musician.name} - ${song.title}`
            : null}
        </StationName>
        <GoToFavoritesIcon
          onClick={handleGoToFavList}
          className={styles.icon}
        />
      </div>
      <div className={styles.wrapperControlBtn}>
        {isShuffledSongs ? (
          <RandomMusicActive onClick={() => song && handleRandomSong()} />
        ) : (
          <RandomMusic onClick={() => song && handleRandomSong()} />
        )}

        <ControlsContainer>
          <IconButton
            className={styles.controlIcon}
            onClick={() => song && handlePrevious(song.id)}
            aria-label="previous"
          >
            <SkipPreviousIcon
              style={{
                fill: "#ffffff",
                width: "36px",
                height: "36px",
                marginLeft: "2px",
              }}
            />
          </IconButton>
          <IconButton
            className={styles.playIcon}
            onClick={handlePlayPause}
            aria-label="play/pause"
          >
            {isPlaying ? (
              <PauseIcon
                style={{ fill: "#ffffff", width: "36px", height: "36px" }}
              />
            ) : (
              <PlayArrowIcon
                style={{
                  fill: "#ffffff",
                  width: "36px",
                  height: "36px",
                  marginLeft: "4px",
                }}
              />
            )}
          </IconButton>
          <IconButton
            className={styles.controlIcon}
            onClick={() => song && handleNext(song.id)}
            aria-label="next"
          >
            <SkipNextIcon
              style={{
                fill: "#ffffff",
                width: "36px",
                height: "36px",
                marginRight: "4px",
              }}
            />
          </IconButton>
        </ControlsContainer>
        {!circuleSong ? (
          <CircleMusic onClick={handleToggleCircle} />
        ) : (
          <CircleMusicActive onClick={handleToggleCircle} />
        )}
      </div>

      {/* Time Control */}
      <TimeControl
        elapsedTime={progress}
        totalTime={duration}
        onTimeChange={handleTimeChange}
      />

      <div style={{ display: "none" }}>
        <ReactPlayer
          ref={playerRef}
          url={`https://slushairu.ru/media/${song?.file}`}
          playing={isPlaying}
          controls={false}
          playsinline
          volume={volume / 100}
          width="100%"
          height="50px"
          onPause={() => setIsPlaying(false)}
          onPlay={() => setIsPlaying(true)}
          onDuration={handleDuration}
          onEnded={handleSongEnd}
          onProgress={handleProgress}
        />
      </div>
    </PlayerContainer>
  );
};

export default PlayerControlsMusicians;
