import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from "react";
import {
  getWalletByTelegramId,
  updateWalletBalance,
  BalanceResponse,
} from "../service/wallets/wallets";

interface WalletContextType {
  wallet: BalanceResponse | null;
  setWallet: React.Dispatch<React.SetStateAction<BalanceResponse | null>>;
  handleGetWalletByTelegramId: (id: string) => Promise<void>;
  handleUpdateWalletBalance: (
    telegramId: string,
    points: string
  ) => Promise<void>;
}

const WalletContext = createContext<WalletContextType | undefined>(undefined);

export const WalletProvider = ({ children }: { children: ReactNode }) => {
  const [wallet, setWallet] = useState<BalanceResponse | null>(null);

  const urlParams = new URLSearchParams(window.location.search);
  const telegramId = urlParams.get("telegram_id") || "1391527029";

  const handleGetWalletByTelegramId = async (id: string): Promise<void> => {
    try {
      const fetchedWallet = await getWalletByTelegramId(id);
      if (fetchedWallet) {
        setWallet(fetchedWallet);
      } else {
        console.error("Failed to fetch wallet");
      }
    } catch (error) {
      console.error("Failed to fetch wallet", error);
    }
  };

  const handleUpdateWalletBalance = async (
    telegramId: string,
    points: string
  ) => {
    try {
      const updatedWallet = await updateWalletBalance({
        telegram_id: telegramId,
        points,
      });
      if (updatedWallet) {
        setWallet(updatedWallet);
      } else {
        console.error("Failed to update wallet balance");
      }
    } catch (error) {
      console.error("Failed to update wallet balance", error);
    }
  };

  useEffect(() => {
    if (telegramId) {
      try {
        handleGetWalletByTelegramId(telegramId);
      } catch (error) {
        console.error("Failed to fetch wallet", error);
      }
    }
  }, [telegramId]);

  return (
    <WalletContext.Provider
      value={{
        wallet,
        setWallet,
        handleGetWalletByTelegramId,
        handleUpdateWalletBalance,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = (): WalletContextType => {
  const context = useContext(WalletContext);
  if (context === undefined) {
    throw new Error("useWallet must be used within a WalletProvider");
  }
  return context;
};
