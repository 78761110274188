import React, { FC, useState } from "react";

import { ReactComponent as CloseIcon } from "../../../assets/icons/closePopupIcon.svg";

import styles from "./TaskModal.module.css";
import { checkTask } from "../../../service/tasks/tasks";
import { useUser } from "../../../context/UserContext";

export type TaskType = "social" | "subscribe";
interface TaskModalProps {
  id: string;
  title: string;
  description: string;
  points: string;
  type: TaskType;
  icon: string;
  link: string;
  onClose: () => void;
}


enum TaskStatus {
  default = "default",
  check = "check",
  rejected = "rejected",
  confirmed = "confirmed",
}

const TaskModal: FC<TaskModalProps> = ({
  id,
  type,
  icon,
  link,
  points,
  description,
  title,
  onClose,
}) => {
  const { user, handleGetUserByTelegramId } = useUser();
  const [status, setStatus] = useState<TaskStatus>(TaskStatus.default);

  const onStart = () => {
    window.open(link, "_blank");

    setStatus(TaskStatus.check);
  };

  const onCheck = async () => {
    try {
      const response = await checkTask(
        user.telegram_id.toString(),
        id,
        type
      ).catch((err) => err);

      if (response.status === 200) {
        setStatus(TaskStatus.confirmed);
        handleGetUserByTelegramId(user.telegram_id.toString());
      } else {
        setStatus(TaskStatus.rejected);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={styles.modalWrapper}>
      <CloseIcon
        className={styles.closeIcon}
        onClick={onClose}
      />

      <div className={styles.taskContent}>
        <img
          className={styles.img}
          src={icon}
          alt="taskIcon"
        />
        <h2 className={styles.title}>{title}</h2>
        {status === TaskStatus.default && (
          <p className={styles.description}>{description}</p>
        )}
        {status === TaskStatus.rejected && (
          <p className={styles.rejected}>You are not subscriber</p>
        )}
        {status === TaskStatus.confirmed && (
          <p className={styles.confirmed}>Confirmed</p>
        )}

        <div className={styles.rewardBlock}>
          <p className={styles.reward}>Reward</p>
          <p className={styles.rewardPoints}>{points}</p>
        </div>

        <button
          onClick={
            status === TaskStatus.default
              ? onStart
              : status === TaskStatus.check
              ? onCheck
              : onClose
          }
          className={styles.actionButton}
        >
          <p>
            {status === TaskStatus.default
              ? "Start"
              : status === TaskStatus.check
              ? "Check"
              : status === TaskStatus.rejected
              ? "Back"
              : "Claim"}
          </p>
        </button>
      </div>
    </div>
  );
};

export default TaskModal;
