import React, { FC } from "react";
import styles from "./TaskItem.module.css";
import { Quizzes, TaskInterface } from "../../service/tasks/tasks";

interface TaskItemProps {
  quizzes: Quizzes[];
  onQuizzClick?: (quizz: Quizzes) => void;
}

const QuizzItem: FC<TaskItemProps> = ({ quizzes, onQuizzClick }) => {
  // const filterByLanguage = (quizzes: Quizzes[]) =>
  //   quizzes.filter((quizz) => quizz.language === "all" || quizz.language === lang);

  return (
    <>
      {quizzes.map((quizz) => (
        <div
          className={styles.refItem}
          key={quizz.id}
          onClick={() => onQuizzClick && onQuizzClick(quizz)}
        >
          <div
            style={{
              background: `url('${quizz.image}')`,
              backgroundSize: "contain",
            }}
            className={styles.avatar}
          ></div>
          <div className={styles.info}>
            <div className={styles.refName}>
              <p className={styles.name}>
                {/* {lang === "en" ? task.name_en : task.name_ru} */}
                {quizz.name}
              </p>
              <p className={styles.username}>{quizz.description}</p>
            </div>

            <div className={styles.reward}>
              <p className={styles.rewardTopText}>Reward</p>
              <p className={styles.rewardText}>{quizz.points}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default QuizzItem;
