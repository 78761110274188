import React, { createContext, useContext, ReactNode } from "react";

const translations = {
  en: {
    menu: "Menu",
    session_ended: "Your session has ended. Please reload the page.",
    something_wrong: "Something went wrong. Please try reloading the page.",
    params_error: "Failed to get parameters. Please try reloading the page.",
    stations: "Stations",
    no_stations:
      "There are currently no available radio stations. We apologize for the inconvenience.",
    invite_friends: "Invite Your Friends",
    your_referral_link: "Your referral link",
    play: "Play",
    pause: "Pause",
    next: "Next",
    previous: "Previous",
    volume: "Volume",
    favorite: "Favorite",
    unfavorite: "Unfavorite",
    favorites: "Favorites",
    no_favorites:
      'You currently have no favorite radio stations. You can add a radio station to your favorites list in the "Radio" tab.',
    language: "Language",
    copyright: "For right holders",
    stations_loading: "We are loading your stations",
    copyright_notice: "Copyright Infringement Notice",
    copyright_text1:
      "Radiogram does not engage in its own broadcasting or retransmission of radio station audio streams. All published materials (links to audio streams and contact information) are taken from open sources on the Internet or were sent by application users. The broadcasting occurs on third-party servers with special software for this purpose.",
    copyright_text2:
      "Logos (trademarks) that have been officially registered belong to their owners. Radiogram uses such materials for informational purposes.",
    copyright_text3:
      "Radiogram respects the intellectual property rights of others and expects its users to do the same. If you are a copyright owner or are authorized to act on their behalf, please report any alleged copyright infringements.",
    copyright_text4:
      'Identify the material that you believe infringes copyright by providing convincing evidence. After receiving the notice, Radiogram will promptly respond to copyright infringement claims and take any action it deems appropriate, including removing the disputed material from the application. To notify, use the feedback form. (<a href="https://t.me/RADIOGRAM_feedback_bot">https://t.me/RADIOGRAM_feedback_bot</a>)',
    radio: "Radio",
    balance_title: "Balance:",
    balance_subtitle: "IMPULSE",
    referral_bonus: "+100 IMPULSE FOR EACH FRIEND YOU INVITE,",
    referral_bonus_2: "+5% OF YOUR FRIENDS' EARNINGS.",
    no_referrals: "Your friends have not joined you yet.",
    player: "Player",
    friends: "Friends",
    bonus_coming_soon: "BONUS (coming soon)",
    feedback: "Feedback",
    search: "Station ot track name...",
    all: "All",
    genres: "Genres",
    countries: "Countries",
    no_options: "No available options yet",
    tasks: "Tasks",
    wallet: "Wallet",
    leaderboard: "Leaderboard",
    game: "Game",
    help: "Help",
    listen_radio: "Listen Radio",
    invite: "Invite your  <span class='highlight'>friends</span>",

    complete_tasks: "Complete Tasks",
    play_games: "Play games",
    up_your_rank: "Up your rank",
    info_up_rank:
      "Earn momentum, increase your rank and get a Mining Amplifier! The higher your rank, the more earning opportunities you have. Use the amplifier to increase your earnings and earn even more momentum for every minute you listen!",
    not_working_in_the_background: "Not working in the background?",
    leaderboard_collect: "Collect",
    leaderboard_text2: "to advance to this league.",
    leaderboard_text3: "Cost to switch",
    bonus: "Bonus: Amplifier",
    help_page: {
      listen_radio: {
        title_1: "Listen to music and mine",
        title_2: "impulses!",
        text_1: "You'll get 1 impulse for every minute of listening.",
        text_2:
          "Look for the radio station of the day and get 2 impulses per minute!",
        text_3: "Advance on the leaderboard and get a mining booster!",
        text_4: "Build up the impulses!",
        text_5: "These can later be exchanged for a valuable project token!",
        text_6: "NFT amplifier - coming soon!",
      },
      invite: {
        title: "Invite your friends!",
        text_1: "Get",
        text_2: "+100 IMPULS for each friend you refer",
        text_3: "+5% OF YOUR FRIENDS' EARNINGS.",
        text_4: "+ TO KARMA",
      },
      tasks: {
        title: "Run tasks",
        text: "Get extra boosts for completing various tasks and passing tests. The more you participate, the more rewards you can get!",
      },
      games: {
        title: "Play games",
        text_1: "Want to earn more impulses? ️",
        text_2: "Play our exciting mini-games and earn extra impulses!",
        text_3:
          "Play, enjoy music and discover new possibilities in RADIOGRAM!",
      },
      up_your_rank: {
        title: "Up your rank",
        text: "Earn momentum, increase your rank and get a Mining Amplifier! The higher your rank, the more earning opportunities you have. Use the amplifier to increase your earnings and earn even more momentum for every minute you listen!",
      },
      background: {
        title_1: "Not working",
        title_2: "in the background?",
        text: "Open your phone settings and find the section related to power or battery consumption. Find the TELEGRAM application and select the “unlimited” option for it. This will prevent the application from shutting down.",
      },
    },
    user_agreement: {
      title: "User Agreement",
      text_1:
        "This User Agreement (hereinafter referred to as the Agreement) governs the relationship between the owner (hereinafter referred to as the Administration) of the web application located at radiogram.website (hereinafter referred to as RADIOGRAM) on one side and the user of the site/application on the other side. RADIOGRAM is not a mass media outlet.",
      text_2:
        "By using RADIOGRAM, you agree to the terms of this Agreement. If you do not agree with the terms of this Agreement, do not use RADIOGRAM!",
      text_3: "Rights and Obligations of the Parties",

      list_1: {
        title: "The user has the right to:",
        item_1: "access information in RADIOGRAM",
        item_2: "alter user ratings",
        item_3:
          "use information from RADIOGRAM for personal non-commercial purposes",
      },

      list_2: {
        title: "The Administration has the right to:",
        item_1:
          "create, modify, or cancel rules at its discretion and necessity",
        item_2: "restrict access to any information",
        item_3: "create, modify, or delete information",
        item_4: "delete accounts",
        item_5: "deny registration without explanation",
      },
      list_3: {
        title: "The user agrees to:",
        item_1: "not disrupt the functioning of RADIOGRAM",
        item_2:
          "not create multiple accounts in RADIOGRAM that actually belong to the same individual",
        item_3:
          "not register an account on behalf of or instead of another person",
        item_4:
          "not use scripts (programs) for automated information gathering and/or interaction with RADIOGRAM and its services",
      },
      list_4: {
        title: "The Administration agrees to:",
        item_1:
          "maintain the operability of RADIOGRAM except in cases where it is impossible due to reasons beyond the Administration's control",
        item_2: "provide comprehensive protection for the User's account",
        item_3:
          "provide all available information about the User to authorized governmental agencies in the cases established by law",
      },
      list_5: {
        title: "Liability of the Parties",
        item_1:
          "The Administration is not responsible for the accuracy of information copied from other sources.",
        item_2:
          "The Administration is not responsible for the discrepancy between the services expected by the User and those actually received.",
        item_3:
          "The Administration is not responsible for services provided by third parties.",
        item_4:
          "In cases of force majeure (armed conflict, emergency situations, natural disasters, etc.), the Administration does not guarantee the preservation of information posted by the User, nor the uninterrupted operation of the information resource.",
        item_5:
          "The Administration is not liable for damages related to technical failures and lack of internet connectivity.",
      },
      list_6: {
        title: "Terms of Validity of the Agreement",
        item_1: "This Agreement comes into effect upon any use of RADIOGRAM.",
        item_2: "The Agreement is valid indefinitely.",
        item_3:
          "The Administration reserves the right to unilaterally modify this Agreement at its discretion.",
        item_4:
          "The Administration does not notify users about changes to the Agreement.",
      },
    },
    onboarding: {
      step_1: {
        title: "Hi,",
        subtitle_1: "Welcome to",
        subtitle_2: "RADIOGRAM!",
        text_1:
          "My name is AMO, and I am a member of the Melodite race. We have suffered a catastrophe and lost our energy sources - the impulses that are our essence.",
        text_2:
          "I have come to Earth to bring you the RADIOGRAM application, a radio player with a unique impulse generation function. When you listen to music on RADIOGRAM, you create the impulses we need to survive.",
        text_3:
          "As we accumulate impulses, we will be able to exchange them for earthly values such as cryptocurrency.",
        text_4: "Start listening to RADIOGRAM now!",
      },
      step_4: {
        title: "Run tasks",
        text: "Get extra boosts for completing various tasks and passing tests. The more you participate, the more rewards you can get!",

        text_5: "Good luck!",
      },
      step_5: {
        subtitle_1: "Get your gift",
        subtitle_2: "+1000 Impulse",
        text_1: "Click the button below to accept the terms",
        text_2: "of the user agreement",
        text_3: "and receive",
        text_4: "your gift!",
        text_5: "Claim",
      },
    },
    transition_1: "To move to the current league",
    transition_2: "you need to spend ",
    transition_3: "Wouldn't you agree?",
    transition_yes: "Yes",
    transition_no: "No",
    wallet_text_1: "This is your in-game wallet",
    wallet_text_2: "you can check your balance",
    invite_text:
      "Join RADIOGRAM and get 100 impulses as a gift! Earn money just by listening to music. Find out what's in store for you!",
    gameTitle: "Games",
    gameText1: "These are our games where you ",
    gameText2: " can earn IMPULSE",
    taskTitle: "Tasks",
    taskText: "Complete tasks and get IMPULSE",
  },

  ru: {
    menu: "Меню",
    session_ended: "Ваша сессия завершена. Перезапустите страницу.",
    something_wrong: "Что-то пошло не так. Попробуйте перезапустить страницу.",
    params_error:
      "Не удалось получить параметры. Попробуйте перезапустить страницу.",
    stations: "Станции",
    no_stations:
      "На данный момент доступных радиостанций нет. Приносим свои извинения.",
    invite_friends: "Пригласите Ваших друзей",
    your_referral_link: "Ваша реферальная ссылка",
    play: "Играть",
    pause: "Пауза",
    next: "Следующий",
    previous: "Предыдущий",
    volume: "Громкость",
    favorite: "Избранное",
    unfavorite: "Убрать из избранного",
    favorites: "Избранное",
    no_favorites:
      'На данный момент у Вас нет избранных радиостанций. Вы можете добавить радиостаннцию в список избранного во вкладке "Радио".',
    language: "Язык",
    copyright: "Правообладателям",
    stations_loading: "Мы загружаем Ваши станции",
    copyright_notice: "Уведомление о нарушении авторских прав",
    copyright_text1:
      "Radiogram не занимается собственной трансляцией или ретрансляций аудиопотоков радиостанций. Все опубликованные материалы (ссылки на аудиопотоки и контактная информация) взяты из открытых источников в сети интернет или были присланы пользователями приложения. Вещания происходит на сторонних серверах со специальным для этого программным обеспечением.",
    copyright_text2:
      "Логотипы (товарные знаки) прошедшие официальную регистрацию, принадлежат их владельцам. Radiogram использует такие материалы в информационных целях.",
    copyright_text3:
      "Radiogram уважает права интеллектуальной собственности других лиц и ожидает что ее пользователи будут делать то же самое. Если вы являетесь владельцем авторских прав или уполномочены действовать от их имени, пожалуйста, сообщите о предполагаемых нарушениях авторских прав.",
    copyright_text4:
      'Укажите материал, который, по вашему мнению, нарушает авторские права предъявив убедительные доказательства. После получения уведомления, Radiogram оперативно отреагирует на заявления о нарушении авторского права, предпримет любые действия по своему усмотрению, в том числе удалит оспариваемый материал с приложения. Для уведомления воспользуйтесь формой обратной связи. (<a href="https://t.me/RADIOGRAM_feedback_bot">https://t.me/RADIOGRAM_feedback_bot</a>)',
    radio: "Радио",
    balance_title: "Balance:",
    balance_subtitle: "IMPULSE",
    referral_bonus: "+100 ИМПУЛЬСОВ ЗА КАЖДОГО ДРУГА, ",
    referral_bonus_2: "+5% ОТ ЗАРАБОТКА ВАШИХ ДРУЗЕЙ.",
    no_referrals: "Ваши друзья еще не присоединились к вам.",
    player: "Плеер",
    friends: "Друзья",
    bonus_coming_soon: "БОНУС (скоро)",
    feedback: "Обратная связь",
    search: "Название станции или трека...",
    all: "Все",
    genres: "Жанры",
    countries: "Страны",
    no_options: "Доступных опций еще нет",
    tasks: "Задания",
    wallet: "Кошелек",
    leaderboard: "Лидерборд",
    game: "Игры",
    help: "Помощь",
    listen_radio: "Слушай музыку",
    invite: "Приглашай  <span class='highlight'>друзей</span>",
    complete_tasks: "Выполняй задания",
    play_games: "Играй в игры",
    up_your_rank: "Повышай свой ранг",
    info_up_rank:
      "Зарабатывайте импульсы, повышайте свой ранг и получайте Усилитель Майнинга! Чем выше ваш ранг, тем больше возможностей для заработка. Используйте усилитель, чтобы увеличить свои доходы и зарабатывать ещё больше импульсов за каждую минуту прослушивания!",
    not_working_in_the_background: "Не работает в фоновом режиме?",
    leaderboard_collect: "Собери",
    leaderboard_text2: "чтобы перейти в эту лигу.",
    leaderboard_text3: "Стоимость перехода",
    bonus: "Бонус: Усилитель",
    help_page: {
      listen_radio: {
        title_1: "Слушай музыку и майни",
        title_2: "импульсы!",
        text_1: "За каждую минуту прослушивания ты будешь получать 1 импульс.",
        text_2: "Ищи радиостанцию дня и получай 2 импульса в минуту!",
        text_3: "Продвигайся в таблице лидеров и получай усилитель майнинга!",
        text_4: "Накапливай импульсы!",
        text_5: "В дальнейшем их можно будет обменять на ценный токен проекта!",
        text_6: "NFT усилитель - скоро!",
      },
      invite: {
        title: "Приглашай друзей!",
        text_1: "Получай",
        text_2: "+100 ИМПУЛЬСОВ ЗА КАЖДОГО ПРИВЕДЕННОГО ДРУГА",
        text_3: "+5% ОТ ЗАРАБОТКА ВАШИХ ДРУЗЕЙ.",
        text_4: "+ В КАРМУ",
      },
      tasks: {
        title: "Выполняй задания",
        text: "Получай дополнительные импульсы за выполнение различных заданий и прохождение тестов. Чем больше вы будете участвовать, тем больше наград сможете получить!",
      },
      games: {
        title: "Играй в игры",
        text_1: "Хотите заработать больше импульсов? ️",
        text_2:
          "Играйте в наши увлекательные мини-игры и зарабатывайте дополнительные импульсы!",
        text_3:
          "Играйте, наслаждайтесь музыкой и открывайте новые возможности в RADIOGRAM!",
      },
      up_your_rank: {
        title: "Повышай свой ранг",
        text: "Зарабатывайте импульсы, повышайте свой ранг и получайте Усилитель Майнинга! Чем выше ваш ранг, тем больше возможностей для заработка. Используйте усилитель, чтобы увеличить свои доходы и зарабатывать ещё больше импульсов за каждую минуту прослушивания!",
      },
      background: {
        title_1: "Не работает в фоновом",
        title_2: "режиме?",
        text: "Откройте настройки вашего телефона и найдите раздел, связанный с энергопотреблением или расходом заряда батареи. Найдите приложение ТЕЛЕГРАМ и выберите для него опцию «без ограничений». Это позволит приложению не отключаться.",
      },
    },
    user_agreement: {
      title: "Пользовательское Соглашение",
      text_1:
        "Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между владельцем (далее Администрация) веб приложения расположенного по адресу radiogram.website (далее RADIOGRAM) с одной стороны и пользователем сайта/приложения с другой. RADIOGRAM не является средством массовой информации.",
      text_2:
        "Используя RADIOGRAM , Вы соглашаетесь с условиями данного соглашения. Если Вы не согласны с условиями данного соглашения, не используйте RADIOGRAM!",
      text_3: "Права и обязанности сторон",

      list_1: {
        title: "Пользователь имеет право:",
        item_1: "получать информацию в RADIOGRAM",
        item_2: "изменять рейтинг пользователей",
        item_3:
          "использовать информацию RADIOGRAM в личных некоммерческих целях",
      },

      list_2: {
        title: "Администрация имеет право:",
        item_1:
          "по своему усмотрению и необходимости создавать, изменять, отменять правила",
        item_2: "ограничивать доступ к любой информации",
        item_3: "создавать, изменять, удалять информацию",
        item_4: "удалять учетные записи",
        item_5: "отказывать в регистрации без объяснения причин",
      },
      list_3: {
        title: "Пользователь обязуется:",
        item_1: "не нарушать работоспособность RADIOGRAM",
        item_2:
          "не создавать несколько учётных записей в RADIOGRAM, если фактически они принадлежат одному и тому же лицу",
        item_3:
          "не регистрировать учетную запись от имени или вместо другого лица.",
        item_4:
          "не использовать скрипты (программы) для автоматизированного сбора информации и/или взаимодействия с RADIOGRAM и его Сервисами",
      },
      list_4: {
        title: "Администрация обязуется:",
        item_1:
          "поддерживать работоспособность RADIOGRAM за исключением случаев, когда это невозможно по независящим от Администрации причинам.",
        item_2:
          "осуществлять разностороннюю защиту учетной записи Пользователя",
        item_3:
          "предоставить всю доступную информацию о Пользователе уполномоченным на то органам государственной власти в случаях, установленных законом",
      },
      list_5: {
        title: "Ответственность сторон",
        item_1:
          "администрация не несет никакой ответственности за достоверность информации, скопированной из других источников",
        item_2:
          "администрация не несёт ответственность за несовпадение ожидаемых Пользователем и реально полученных услуг",
        item_3:
          "администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами",
        item_4:
          "в случае возникновения форс-мажорной ситуации (боевые действия, чрезвычайное положение, стихийное бедствие и т. д.) Администрация не гарантирует сохранность информации, размещённой Пользователем, а также бесперебойную работу информационного ресурса",
        item_5:
          "администрация не отвечает за ущерб, связанный с техническим сбоем и отсутствием подключения к интернету.",
      },
      list_6: {
        title: "Условия действия Соглашения",
        item_1:
          "Данное Соглашение вступает в силу при любом использовании RADIOGRAM.",
        item_2: "Соглашение действует бессрочно.",
        item_3:
          "Администрация оставляет за собой право в одностороннем порядке изменять данное соглашение по своему усмотрению.",
        item_4:
          "Администрация не оповещает пользователей об изменении в Соглашении.",
      },
    },
    onboarding: {
      step_1: {
        title: "Привет,",
        subtitle_1: "Добро пожаловать в",
        subtitle_2: "RADIOGRAM!",
        text_1:
          "Меня зовут АМО, я – представитель расы Мелодиты. Мы пережили катастрофу и потеряли наши источники энергии – импульсы, которые являются нашей сущностью.",
        text_2:
          "Я прибыл на Землю, чтобы передать вам приложение RADIOGRAM, радиоплеер с уникальной функцией генерации импульсов. Когда вы слушаете музыку в RADIOGRAM, вы создаете импульсы, необходимые нам для выживания.",
        text_3:
          "По мере накопления импульсов, мы сможем обменивать их на земные ценности, такие как криптовалюта.",
        text_4: "Начинай слушать RADIOGRAM прямо сейчас!",
      },
      step_4: {
        title: "Выполняй задания",
        text: "Получай дополнительные импульсы за выполнение различных заданий и прохождение тестов. Чем больше вы будете участвовать, тем больше наград сможете получить!",

        text_5: "Удачи!",
      },
      step_5: {
        subtitle_1: "Получи свой подарок",
        subtitle_2: "+1000 импульсов",
        text_1: "Нажми на кнопку ниже, чтобы принять условия",
        text_2: "пользовательского соглашения",
        text_3: "и получить свой",
        text_4: "подарок!",
        text_5: "Принимаю",
      },
    },
    transition_1: "Для перехода в текущую лигу вам",
    transition_2: "необходимо потратить",
    transition_3: "Вы согласны?",
    transition_yes: "Да",
    transition_no: "Нет",
    wallet_text_1: "Это ваш игровой кошелек",
    wallet_text_2: "Вы можете проверить свой баланс",
    invite_text:
      "Присоединяйся к RADIOGRAM и получи 100 импульсов в подарок! Зарабатывай просто слушая музыку. Узнай, что тебя ждет!",
    gameTitle: "ИГРЫ",
    gameText1: "Это наши игры, в которых вы ",
    gameText2: " можете заработать импульсы",
    taskTitle: "ЗАДАНИЯ",
    taskText: "Выполняй задания и получай импульсы",
  },
};

type Translations = typeof translations.en;

const I18nContext = createContext<Translations>(translations.en);

interface I18nProviderProps {
  lang: keyof typeof translations;
  children: ReactNode;
}

export const I18nProvider: React.FC<I18nProviderProps> = ({
  lang,
  children,
}) => {
  return (
    <I18nContext.Provider value={translations[lang] || translations.en}>
      {children}
    </I18nContext.Provider>
  );
};

export const useTranslate = () => {
  const context = useContext(I18nContext);
  if (!context) {
    throw new Error("useTranslate must be used within an I18nProvider");
  }
  return context;
};

export const translate = (
  key: string,
  lang: keyof typeof translations
): string => {
  const t = translations[lang] || translations.en;
  return key.split(".").reduce((obj: any, k: string) => {
    return obj && obj[k] !== undefined ? obj[k] : key;
  }, t);
};
