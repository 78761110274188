import { useSearchParams } from "react-router-dom";
import style from "./LanguageSwitcher.module.css";
import { useLanguage } from "../../context/LanguageContext";

interface LanguageSelectionProps {
  onLanguageSelect: () => void;
  first: boolean;
  onClick?: (newVal: number) => void;
}

export const LanguageSwitcher: React.FC<LanguageSelectionProps> = ({
  onLanguageSelect,
  first,
  onClick,
}) => {
  const { setLang } = useLanguage();

  const handleLanguageSelect = (lang: string, newValue?: number) => {
    setLang(lang);
    onLanguageSelect();

    if (!first && onClick) {
      onClick(newValue!);
    }
  };

  return (
    <div className={first ? style.wrapper : style.wrapperMenu}>
      <h1 className={style.title}>Select a language</h1>
      <p
        className={style.langNameEn}
        onClick={() => handleLanguageSelect("en")}
      >
        English
      </p>
      <p
        className={style.langNameRu}
        onClick={() => handleLanguageSelect("ru")}
      >
        Русский
      </p>
    </div>
  );
};
