import React, { FC, useState } from "react";
import TaskModal from "../../components/Task/TaskModal/TaskModal";
import TaskItem from "../../components/TaskItem/TaskItem";
import styles from "./refLink.module.css";
import { useTasks } from "../../context/TaskContext";
import { Quizzes, TaskInterface } from "../../service/tasks/tasks";
import QuizzItem from "../../components/TaskItem/QuizzItem";
import QuizzModal from "../../components/Task/QuizzModal/QuizzModal";
import { useTranslate } from "../../i18n";

const Tasks: FC<{ lang: "en" | "ru" }> = ({ lang }) => {
  const {
    dailyTasks,
    basicTasks,
    completedDailyTasks,
    completedBasicTasks,
    quizzes,
  } = useTasks();
  const [selectedTask, setSelectedTask] = useState<any>(null);
  const [selectedQuizz, setSelectedQuizz] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [isOpenQuizz, setIsOpenQuizz] = useState(false);

  const translate = useTranslate();

  const handleOpen = (task: TaskInterface) => {
    setSelectedTask(task);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedTask(null);
    setOpen(false);
  };

  const handleOpenQuizz = (quizz: Quizzes) => {
    setSelectedQuizz(quizz);
    setIsOpenQuizz(true);
  };

  const handleCloseQuizz = () => {
    setSelectedQuizz(null);
    setIsOpenQuizz(false);
  };

  return (
    <div className={styles.pageWrapper}>
      {open && selectedTask && (
        <TaskModal
          id={selectedTask.id}
          title={lang === "en" ? selectedTask.name_en : selectedTask.name_ru}
          description={
            lang === "en"
              ? selectedTask.description_en
              : selectedTask.description_ru
          }
          points={selectedTask.points}
          type={selectedTask.type}
          icon={`https://slushairu.ru${selectedTask.icon}`}
          link={selectedTask.link}
          onClose={handleClose}
        />
      )}

      {isOpenQuizz && selectedQuizz && (
        <QuizzModal
          id={selectedQuizz.id}
          title={selectedQuizz.name}
          description={selectedQuizz.description}
          points={selectedQuizz.points}
          icon={`${selectedQuizz.image}`}
          link={selectedQuizz.url}
          onClose={handleCloseQuizz}
        />
      )}
      <h1 className={styles.pageTitle}>{translate.taskTitle}</h1>
      <p className={styles.pageSubtitle}>{translate.taskText}</p>

      <div className={styles.refListWrapper}>
        <div className={`${styles.wrapper} ${styles.wrapperInfo}`}>
          <p className={styles.textStyle}>Daily</p>
        </div>
        <ul className={styles.list}>
          <TaskItem
            tasks={dailyTasks}
            lang={lang}
            onTaskClick={handleOpen}
          />
          <TaskItem
            tasks={completedDailyTasks}
            lang={lang}
            completed
          />
        </ul>

        <div className={`${styles.wrapper} ${styles.wrapperError}`}>
          <p className={styles.textStyle}>Basic</p>
        </div>
        <ul className={styles.list}>
          <TaskItem
            tasks={basicTasks}
            lang={lang}
            onTaskClick={handleOpen}
          />
          <TaskItem
            tasks={completedBasicTasks}
            lang={lang}
            completed
          />
        </ul>

        <div className={`${styles.wrapper} ${styles.wrapperSuccessful}`}>
          <p className={styles.textStyle}>Quiz</p>
        </div>
        <ul className={styles.list}>
          <QuizzItem quizzes={quizzes} onQuizzClick={handleOpenQuizz}/>
        </ul>
      </div>
    </div>
  );
};

export default Tasks;
