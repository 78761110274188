import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import { useUser } from "../context/UserContext";
import {
  getActiveTasks,
  getQuizzes,
  postCompletedQuizzes,
  Quizzes,
  TaskInterface,
} from "../service/tasks/tasks";

interface TasksContextProps {
  dailyTasks: TaskInterface[];
  basicTasks: TaskInterface[];
  completedDailyTasks: TaskInterface[];
  completedBasicTasks: TaskInterface[];
  fetchTasks: (telegramId: number) => Promise<void>;
  getQuiz: (telegramId: number) => Promise<void>;
  quizzes: Quizzes[];
  getSelectedQuiz: (quizId: number) => Quizzes | undefined;
  completedQuizz: (telegramId: string, quizId: number) => Promise<void>;
}

const TasksContext = createContext<TasksContextProps | undefined>(undefined);

export const TasksProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useUser();

  const [dailyTasks, setDailyTasks] = useState<TaskInterface[]>([]);
  const [basicTasks, setBasicTasks] = useState<TaskInterface[]>([]);
  const [completedDailyTasks, setCompletedDailyTasks] = useState<
    TaskInterface[]
  >([]);
  const [completedBasicTasks, setCompletedBasicTasks] = useState<
    TaskInterface[]
  >([]);

  const [quizzes, setQuizzes] = useState<Quizzes[]>([]);

  const fetchTasks = async (telegramId: number) => {
    try {
      const data = await getActiveTasks(telegramId);

      if (data) {
        const activeTasks = [
          ...data.active_subscribe_tasks.map((task: any) => ({
            ...task,
            type: "subscribe",
            link: task.channel_url,
          })),
          ...data.active_social_tasks.map((task: any) => ({
            ...task,
            type: "social",
            link: task.social_link,
          })),
        ];

        const completedTasks = [
          ...data.completed_subscribe_tasks,
          ...data.completed_social_tasks,
        ];

        setDailyTasks(activeTasks.filter((task) => task.every_day));
        setBasicTasks(activeTasks.filter((task) => !task.every_day));
        setCompletedDailyTasks(completedTasks.filter((task) => task.every_day));
        setCompletedBasicTasks(
          completedTasks.filter((task) => !task.every_day)
        );
      }
    } catch (error) {
      console.error("Failed to fetch tasks:", error);
    }
  };

  const getQuiz = async (telegramId: number) => {
    try {
      const data = await getQuizzes(telegramId);
      if (data) {
        setQuizzes(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getSelectedQuiz = (quizId: number) => {
    return quizzes.find((quiz) => quiz.id === quizId);
  };

  const completedQuizz = async (telegramId: string, quizId: number) => {
    try {
      await postCompletedQuizzes(telegramId, quizId);
      await getQuiz(Number(telegramId));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user?.telegram_id) {
      fetchTasks(user.telegram_id);
      getQuiz(user.telegram_id);
    }
  }, [user]);

  return (
    <TasksContext.Provider
      value={{
        dailyTasks,
        basicTasks,
        completedDailyTasks,
        completedBasicTasks,
        quizzes,
        fetchTasks,
        getQuiz,
        getSelectedQuiz,
        completedQuizz,
      }}
    >
      {children}
    </TasksContext.Provider>
  );
};

export const useTasks = () => {
  const context = useContext(TasksContext);
  if (!context) {
    throw new Error("useTasks must be used within a TasksProvider");
  }
  return context;
};
