import React, { FC, useState } from "react";

import { ReactComponent as CloseIcon } from "../../../assets/icons/closePopupIcon.svg";

import styles from "./QuizzModal.module.css";
import { checkTask } from "../../../service/tasks/tasks";
import { useUser } from "../../../context/UserContext";
import { useTasks } from "../../../context/TaskContext";
import { useWallet } from "../../../context/WalletContext";

export type TaskType = "social" | "subscribe";
interface TaskModalProps {
  id: string;
  title: string;
  description: string;
  points: string;
  icon: string;
  link: string;
  onClose: () => void;
}

enum TaskStatus {
  default = "default",
  check = "check",
  rejected = "rejected",
  confirmed = "confirmed",
}

const QuizzModal: FC<TaskModalProps> = ({
  id,
  icon,
  link,
  points,
  description,
  title,
  onClose,
}) => {
  const { user, setUser } = useUser();
  // const [status, setStatus] = useState<TaskStatus>(TaskStatus.default);
  const [startQuizz, setStartQuizz] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
  const [isCompleted, setIsCompleted] = useState(false);

  const { getSelectedQuiz, completedQuizz } = useTasks();
  const selectedQuiz = getSelectedQuiz(Number(id));
  const [answersResults, setAnswersResults] = useState<boolean[]>([]);
  const [numberQuestion, setNumberQuestion] = useState(1);

  const currentQuestion = selectedQuiz?.questions[currentQuestionIndex];
  const handleNext = () => {
    if (selectedQuiz && selectedAnswer !== null) {
      const currentAnswer = currentQuestion?.answers.find(
        (answer) => answer.id === selectedAnswer
      );

      if (currentAnswer) {
        setAnswersResults((prevResults) => [
          ...prevResults,
          currentAnswer.is_correct,
        ]);
      }

      if (currentQuestionIndex < selectedQuiz.questions.length - 1) {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        setSelectedAnswer(null);
      } else {
        setIsCompleted(true);
      }

      setNumberQuestion(numberQuestion + 1);
    }
  };

  const handleAnswerSelect = (answerId: number) => {
    setSelectedAnswer(answerId);
  };

  const handleCompletedQuiz = () => {
    onClose();
    if (user && selectedQuiz) {
      completedQuizz(user.telegram_id.toString(), selectedQuiz?.id);

      setUser((prev: any) => {
        return {
          ...prev,
          wallet: {
            ...prev?.wallet,
            balance: (
              parseInt(prev?.wallet?.balance) + selectedQuiz.points
            ).toString(),
          },
        };
      });
    }
  };

  const isNotCorrect = answersResults.some((result) => !result);

  const resetQuiz = () => {
    setCurrentQuestionIndex(0);
    setAnswersResults([]);
    setSelectedAnswer(null);
    setIsCompleted(false);
    setNumberQuestion(1);
  };

  // const onStart = () => {
  //   window.open(link, "_blank");

  //   setStatus(TaskStatus.check);
  // };

  // const onCheck = async () => {
  //   try {
  //     const response = await checkTask(
  //       user.telegram_id.toString(),
  //       id,
  //       type
  //     ).catch((err) => err);

  //     if (response.status === 200) {
  //       setStatus(TaskStatus.confirmed);
  //       handleGetUserByTelegramId(user.telegram_id.toString());
  //     } else {
  //       setStatus(TaskStatus.rejected);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.taskContent}>
        <CloseIcon
          className={styles.closeIcon}
          onClick={onClose}
        />
        {selectedQuiz && (
          <>
            {!startQuizz ? (
              <>
                <img
                  className={styles.img}
                  src={icon}
                  alt="taskIcon"
                />
                <h2 className={styles.title}>{title}</h2>

                <p className={styles.description}>{description}</p>

                <div className={styles.rewardBlock}>
                  <p className={styles.reward}>Reward</p>
                  <p className={styles.rewardPoints}>{points}</p>
                </div>

                <a
                  href={selectedQuiz.url}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => setStartQuizz(true)}
                  className={styles.actionButton}
                >
                  <p>Start</p>
                </a>
              </>
            ) : (
              <>
                <img
                  className={styles.img}
                  src={icon}
                  alt="taskIcon"
                />
                <h2 className={styles.title}>{title}</h2>
                {!isCompleted ? (
                  <>
                    <p
                      className={styles.questionsTitleNum}
                    >{`Вопрос №${numberQuestion}`}</p>
                    <p className={styles.questionsTitle}>
                      {currentQuestion?.text}
                    </p>
                    <div className={styles.answers}>
                      {currentQuestion?.answers.map((answer) => (
                        <div
                          key={answer.id}
                          onClick={() => handleAnswerSelect(answer.id)}
                          className={
                            selectedAnswer === answer.id
                              ? styles.selected
                              : styles.answer
                          }
                        >
                          <p className={styles.answerText}>{answer.text}</p>
                        </div>
                      ))}
                    </div>
                    <button
                      onClick={handleNext}
                      disabled={selectedAnswer === null}
                      className={styles.actionButton}
                    >
                      Next
                    </button>
                  </>
                ) : isNotCorrect ? (
                  <>
                    <div className={styles.wrapperResult}>
                      <p className={styles.errorAnswer}>Упс!</p>
                      <p className={styles.errorAnswer}>
                        Вы не правильно ответили на вопросы.
                      </p>
                      <p className={styles.errorAnswerTry}>
                        Попробуйте еще раз!
                      </p>
                    </div>

                    <div className={styles.rewardBlock}>
                      <p className={styles.reward}>Reward</p>
                      <p className={styles.rewardPoints}>{points}</p>
                    </div>

                    <button
                      onClick={() => {
                        resetQuiz();
                        setStartQuizz(false);
                      }}
                      className={styles.actionButton}
                    >
                      <p>Back</p>
                    </button>
                  </>
                ) : (
                  <>
                    <div className={styles.wrapperResult}>
                      <p className={styles.answerConfirmed}>Confirmed</p>
                    </div>
                    <div className={styles.rewardBlock}>
                      <p className={styles.reward}>Reward</p>
                      <p className={styles.rewardPoints}>{points}</p>
                    </div>

                    <button
                      onClick={() => {
                        // resetQuiz();
                        // setStartQuizz(false);
                        handleCompletedQuiz();
                      }}
                      className={styles.actionButton}
                    >
                      <p>Claim</p>
                    </button>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default QuizzModal;
