import React, { useState, useEffect } from "react";
import style from "./TimerGame.module.css";
import FortuneWheel from "../FortuneWheel/FortuneWheel";
import { useGames } from "../../context/GameContext";
interface TimerProps {
  timeLeftSeconds: number;
  onClick?: () => void ;
}

export const TimerGame: React.FC<TimerProps> = ({
  timeLeftSeconds,
  onClick,
}) => {
  const [timeLeft, setTimeLeft] = useState<number>(timeLeftSeconds);
  const {status} = useGames()

  useEffect(() => {
    // Оновлюємо початковий час при зміні пропса
    setTimeLeft(timeLeftSeconds);
  }, [timeLeftSeconds]);

  useEffect(() => {
    if (timeLeft <= 0) return;

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(intervalId);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const formatTimeLeft = (seconds: number) => {
    if (seconds <= 0) return "0h 0m";

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
  };
  return (
    <div className={style.wrapper}>
      {timeLeft > 0 && !status ? (
        <div
          className={style.btnWrapper}
        >
          <p className={style.btnText}> {formatTimeLeft(timeLeft)}</p>
        </div>
      ) : (
        <div className={style.btnWrapper} onClick={onClick}>
          <p className={style.btnText}>Play</p>
        </div>
      )}
    </div>
  );
};
