import React, { createContext, useContext, useState, useEffect } from 'react';

interface LanguageContextType {
  lang: string;
  setLang: (lang: string) => void;
  isLanguageSelected: boolean;
  setIsLanguageSelected: (selected: boolean) => void;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [lang, setLang] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlLang = urlParams.get('lang');
    const savedLanguage = localStorage.getItem('language');
    return urlLang || savedLanguage || 'en';
  });
  
  const [isLanguageSelected, setIsLanguageSelected] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return !!urlParams.get('lang') || !!localStorage.getItem('language');
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlLang = urlParams.get('lang');
    
    if (urlLang) {
      setLang(urlLang);
      localStorage.setItem('language', urlLang);
      setIsLanguageSelected(true);
    }
  }, []);

  const handleSetLanguage = (newLanguage: string) => {
    setLang(newLanguage);
    localStorage.setItem('language', newLanguage);
    setIsLanguageSelected(true);
    
    // Update URL without navigation
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('lang', newLanguage);
    window.history.pushState({}, '', newUrl);
  };

  return (
    <LanguageContext.Provider 
      value={{ 
        lang, 
        setLang: handleSetLanguage,
        isLanguageSelected,
        setIsLanguageSelected
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};