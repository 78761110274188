import React, { useEffect, useRef } from "react";
import style from "./AudioSpectrum.module.css";
import { usePlayer } from "../../context/PlayerContext";

interface AudioSpectrumProps {
  audioSrc: string;
  startAngle?: number;
  smoothing?: number;
  volume?: number;
}

const AudioCircleSpectrum: React.FC<AudioSpectrumProps> = ({
  audioSrc,
  startAngle = 4.75,
  smoothing = 0.7,
  volume,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const sourceRef = useRef<MediaElementAudioSourceNode | null>(null);
  const { isPlaying } = usePlayer();

  useEffect(() => {
    const canvas = canvasRef.current;
    const audio = audioRef.current;

    if (!canvas || !audio) return;

    const canvasCtx = canvas.getContext("2d");
    if (!canvasCtx) return;

    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext ||
        (window as any).webkitAudioContext)();
    }

    const audioContext = audioContextRef.current;

    if (!sourceRef.current) {
      sourceRef.current = audioContext.createMediaElementSource(audio);
    }

    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 256;
    analyser.smoothingTimeConstant = smoothing;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    sourceRef.current.connect(analyser);
    analyser.connect(audioContext.destination);

    const draw = () => {
      requestAnimationFrame(draw);

      analyser.getByteFrequencyData(dataArray);

      const leftHalf = Array.from(dataArray.slice(0, bufferLength / 2));
      const rightHalf = Array.from(
        dataArray.slice(0, bufferLength / 2)
      ).reverse();
      const mirroredData = [...leftHalf, ...rightHalf];

      canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;
      const radius = 80;
      const barWidth = 4;

      mirroredData.forEach((value, index) => {
        const angle = startAngle + (index / mirroredData.length) * 2 * Math.PI;
        const barHeight = Math.max((value / 255) * 100, 10);

        const xStart = centerX + Math.cos(angle) * radius;
        const yStart = centerY + Math.sin(angle) * radius;
        const xEnd = centerX + Math.cos(angle) * (radius + barHeight);
        const yEnd = centerY + Math.sin(angle) * (radius + barHeight);

        canvasCtx.beginPath();
        canvasCtx.moveTo(xStart, yStart);
        canvasCtx.lineTo(xEnd, yEnd);
        canvasCtx.strokeStyle = `rgb(${255 - value}, ${100}, ${400 - value})`;
        canvasCtx.lineWidth = barWidth;
        canvasCtx.stroke();
      });
    };

    draw();

    return () => {
      analyser.disconnect();
      sourceRef.current?.disconnect();
    };
  }, [startAngle, smoothing]);

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current
          .play()
          .catch((err) => console.error("Play Error:", err));
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlaying]);

  useEffect(() => {
    const audio = audioRef.current;
    const audioContext = audioContextRef.current;

    if (audio && audioContext) {
      audio.pause();
      audio.load();

      const startAudio = async () => {
        try {
          await audioContext.resume();
          await audio.play();
        } catch (err) {
          console.error("Error starting audio:", err);
        }
      };

      startAudio();
    }
  }, [audioSrc]);

  useEffect(() => {
    if (audioRef.current && volume) {
      audioRef.current.volume = volume / 100;
    }
  }, [volume]);

  return (
    <div className={style.spectrumContainer}>
      <audio
        ref={audioRef}
        src={audioSrc}
        onPlay={() => {
          if (isPlaying) {
            audioRef.current?.play();
          } else {
            audioRef.current?.pause();
          }
        }}
        crossOrigin="anonymous"
      />
      <canvas
        className={style.wrapper}
        ref={canvasRef}
        width={320}
        height={520}
      />
    </div>
  );
};

export default AudioCircleSpectrum;
