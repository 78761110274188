import { axiosInstance } from "../axiosConfig/axiosConfig";
import { TaskType } from "../../components/Task/TaskModal/TaskModal";

export interface TaskInterface {
  link: string;
  type: number;
  id: number;
  name: string;
  name_en: string;
  name_ru: string;
  description: string;
  description_en: string;
  description_ru: string;
  icon: string;
  channel_link: number;
  channel_url: string;
  points: number;
  every_day: boolean;
  language: string;
}

export interface Question {
  id: number;
  answers: Answer[];
  text: string;
  quiz: number;
}

export interface Answer {
  id: number;
  text: string;
  is_correct: boolean;
  question: number;
}

export interface Quizzes {
  id: number;
  questions: Question[];
  name: string;
  description: string;
  url: string;
  image: string;
  points: number;
}

export async function getActiveTasks(telegramId: number) {
  const response = await axiosInstance.get(
    `/api/active-tasks/?telegram_id=${telegramId}`
  );
  return response.data;
}

export async function checkTask(
  id: string,
  taskId: string,
  taskType: TaskType
): Promise<any> {
  return await axiosInstance.get(
    `/api/complete-task/?task_id=${taskId}&task_type=${taskType}&telegram_id=${id}`
  );
}

export async function getQuizzes(telegram_id: number) {
  const response = await axiosInstance.get("/api/quizzes/", {
    params: { telegram_id },
  });
  return response.data;
}

export async function postCompletedQuizzes(telegramId: string, quizId: number) {
  const response = await axiosInstance.post("/api/complete-quiz/", {
    telegram_id: telegramId,
    quiz_id: quizId,
  });
  return response.data;
}
