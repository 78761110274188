import style from "./Games.module.css";
import Img from "../../assets/img/wheel.png";
import { TimerGame } from "../../components/TimerGame/TimerGame";
import { useGames } from "../../context/GameContext";
import { useEffect, useState } from "react";
import FortuneWheel from "../../components/FortuneWheel/FortuneWheel";
import { useTranslate } from "../../i18n";

export const GamesPage = () => {
  const { lastLaunchDate, fetchSpinResult, status, fetchSpinStatus } =
    useGames();
  const [isOpen, setIsOpen] = useState(false);
  const translate = useTranslate()

  const handleClickOpen = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    fetchSpinStatus();
    if (!status) {
      fetchSpinResult();
      
    }
  }, [fetchSpinResult, status, fetchSpinStatus]);

  return (
    <>
      {/* {isOpen ? (
        <>
        <FortuneWheel/>
        </>
      ) : (
          
    <div className={style.pageWrapper}>
      <h1 className={style.title}>Games</h1>
      <p className={style.subtitle}>These are our games where you </p>
      <p className={style.subtitle}>can earn IMPULSE </p>

      <div className={style.blockGame}>
        <h2 className={style.titleGame}>Fortune wheel</h2>
        <img
          src={Img}
          alt=""
          className={style.imgBlock}
        />
        <div className={style.wrapperBtn}>
          <TimerGame timeLeftSeconds={Number(lastLaunchDate)}  onClick={handleClickOpen}/>
        </div>
      </div>
    </div>
      )} */}

      <div className={style.pageWrapper}>
        <h1 className={style.title}>{translate.gameTitle}</h1>
        <p className={style.subtitle}>{translate.gameText1}</p>
        <p className={style.subtitle}>{translate.gameText2}</p>

        <div className={style.blockGame}>
          <h2 className={style.titleGame}>Fortune wheel</h2>
          <img
            src={Img}
            alt=""
            className={style.imgBlock}
          />
          <div className={style.wrapperBtn}>
            <TimerGame
              timeLeftSeconds={Number(lastLaunchDate)}
              onClick={status ? handleClickOpen : undefined}
            />
          </div>
        </div>
      </div>

      {isOpen && <FortuneWheel onClose={handleClickOpen} />}
    </>
  );
};
