import React, { FC } from "react";
import styles from "./TaskItem.module.css";
import { TaskInterface } from "../../service/tasks/tasks";

interface TaskItemProps {
  tasks: TaskInterface[];
  lang: "en" | "ru";
  completed?: boolean;
  onTaskClick?: (task: TaskInterface) => void;
}

const TaskItem: FC<TaskItemProps> = ({
  tasks,
  lang,
  completed,
  onTaskClick,
}) => {
  const filterByLanguage = (tasks: TaskInterface[]) =>
    tasks.filter((task) => task.language === "all" || task.language === lang);

  return (
    <>
      {filterByLanguage(tasks).map((task) => (
        <div
          className={styles.refItem}
          key={task.id + task.type}
          onClick={() => onTaskClick && onTaskClick(task)}
        >
          <div
            style={{
              background: `url('https://slushairu.ru${task.icon}')`,
              backgroundSize: "contain",
            }}
            className={styles.avatar}
          ></div>
          <div className={styles.info}>
            <div className={styles.refName}>
              <p className={styles.name}>
                {lang === "en" ? task.name_en : task.name_ru}
              </p>
              <p className={styles.username}>
                {lang === "en" ? task.description_en : task.description_ru}
              </p>
            </div>
            {completed ? (
              <div className={styles.rewardDone}>
                <p className={styles.done}>DONE</p>
              </div>
            ) : (
              <div className={styles.reward}>
                <p className={styles.rewardTopText}>Reward</p>
                <p className={styles.rewardText}>{task.points}</p>
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default TaskItem;
